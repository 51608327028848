import { Link } from "@remix-run/react";
import { cn } from "~/toolkit/components/utils";
import { Logo } from "./Logo";

interface AppLogosProps {
  className: string;
}

export function AppLogos({ className = "" }: AppLogosProps) {
  return (
    <div className={cn("flex gap-4 items-center", className)}>
      <Logo className="text-primary h-6 w-6" />
      <Link
        to="/"
        className="text-xl lg:text-2xl font-bold normal-case link-hover text-gray-700"
      >
        Knowl<span className="text-primary-focus font-extrabold">edge</span>
      </Link>
    </div>
  );
}
