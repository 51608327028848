import {
  IoLogOutOutline as LogOutIcon,
  IoPersonOutline as PersonIcon,
} from "react-icons/io5";
import { SUBSCRIPTION_LABELS } from "~/features/subscriptions/subscription.types";
import { AvatarImage } from "~/toolkit/components/avatar/Avatar";
import { DropdownMenu } from "~/toolkit/components/dropdown/DropdownMenu";
import { CurrentUser } from "../../auth/useCurrentUser";
interface AccountDropodownProps {
  user: CurrentUser;
}

export function AccountDropdown({ user }: AccountDropodownProps) {
  if (!user) return null;
  return (
    <DropdownMenu
      classNames={{
        trigger: "btn-circle",
      }}
      trigger={
        <AvatarImage
          name={user?.name || user?.email}
          className="text-gray-600"
        />
      }
    >
      <DropdownMenu.Separator />

      <DropdownMenu.Label>
        {user?.name || user?.email}{" "}
        <span className="text-primary">
          {SUBSCRIPTION_LABELS[user?.plan || "free"]}
        </span>
      </DropdownMenu.Label>
      <DropdownMenu.LinkWithIcon
        to="/my-profile"
        icon={<PersonIcon size={15} />}
      >
        View Usage
      </DropdownMenu.LinkWithIcon>
      <DropdownMenu.Item aria-label="Log out" asChild>
        <a href="/logout" className="grid grid-cols-[24px_auto] items-center">
          <LogOutIcon size={18} />
          Log out
        </a>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
}
