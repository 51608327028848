export function Logo({ className = "" }) {
  return (
    <svg
      className={className}
      width="137"
      height="154"
      viewBox="0 0 137 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.771 81.964C89.512 94.115 78.864 103.318 66.057 103.318C53.252 103.318 42.602 94.115 40.345 81.964H0.134003V153.486H59.033C104.751 153.486 134.425 123.593 136.587 81.964H91.771V81.964Z"
        fill="currentColor"
      />
      <path
        d="M40.346 72.35C42.603 60.2 53.253 50.996 66.058 50.996C78.865 50.996 89.513 60.199 91.772 72.35H136.603C134.561 30.047 104.852 0.828995 59.034 0.828995H0.134003V72.35H40.346Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LogoWithLabel({ className = "" }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="481.384px"
      height="200px"
      viewBox="0 0 481.384 200"
      enableBackground="new 0 0 481.384 200"
      className={className}
    >
      <g>
        <path
          fill="currentColor"
          d="M108.771,116.964c-2.259,12.151-12.907,21.354-25.714,21.354c-12.805,0-23.455-9.203-25.712-21.354H17.134
		v71.522h58.899c45.718,0,75.392-29.893,77.554-71.522H108.771z"
        />
        <path
          fill="currentColor"
          d="M57.346,107.35c2.257-12.15,12.907-21.354,25.712-21.354c12.807,0,23.455,9.203,25.714,21.354h44.831
		c-2.042-42.303-31.751-71.521-77.569-71.521H17.134v71.521H57.346z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M208.461,123.88c13.115,1.198,21.237,10.253,21.237,24.101c0,15.645-10.252,22.568-28.826,22.568
		c-3.196,0-6.725-0.2-7.39-0.267l-1.798-0.134h-0.066l-1.931-0.266v-40.811c-0.999,0.066-1.798,0.267-2.264,0.799
		c-0.6,0.533-0.6,1.199-0.6,1.531l-0.799,0.799h-4.394c-0.133-2.063,0.399-4.326,2.197-6.125c1.265-1.197,3.195-2.196,5.259-2.196
		H208.461z M195.213,165.223c2.396,0.133,8.255,0.332,10.252,0.332c11.252,0,18.774-6.457,18.774-18.174
		c0-10.785-6.392-17.842-16.511-18.309h-12.516V165.223z"
        />
        <path
          fill="currentColor"
          d="M270.506,153.172c0,10.187-7.123,17.443-16.909,17.443c-9.92,0-16.777-7.523-16.777-17.376
		s6.857-17.642,16.844-17.642C263.516,135.598,270.506,143.119,270.506,153.172z M242.079,153.172
		c0,7.457,4.46,12.517,11.584,12.517c7.39,0,11.584-5.459,11.584-12.782c0-7.523-4.527-12.383-11.584-12.383
		C246.14,140.523,242.079,146.115,242.079,153.172z"
        />
        <path
          fill="currentColor"
          d="M295.469,129.473v40.543h-5.525v-40.543h-9.587c-0.932,0-1.465,0.732-1.465,1.397
		c0,0.267,0.134,0.532,0.399,0.732h-5.592c-0.2-1.931,0.466-3.995,1.864-5.393c1.198-1.198,2.862-1.931,4.593-1.931h30.691v5.193
		H295.469z"
        />
        <path
          fill="currentColor"
          d="M347.795,167.02c-1.198,2.597-3.729,3.596-5.726,3.596c-2.663,0-5.06-1.731-6.125-4.062
		c-2.263,2.796-5.725,4.062-9.453,4.062c-9.653,0-14.58-8.322-14.58-17.31c0-10.187,6.325-17.708,16.511-17.708
		c1.997,0,5.459,0.266,7.855,0.599h4.261v27.827c0,0.933,0.799,1.531,1.465,1.531c0.732,0,1.132-0.332,1.598-1.397L347.795,167.02z
		 M335.412,141.057c-2.13-0.134-5.393-0.533-7.323-0.533c-7.189,0-10.918,5.525-10.918,12.648c0,6.459,3.062,12.517,9.387,12.517
		c3.861,0,6.657-1.997,8.056-4.86c0.532-1.064,0.799-2.264,0.799-3.728V141.057z"
        />
        <path
          fill="currentColor"
          d="M380.148,164.023c0,0.732,0.732,1.531,1.531,1.531c0.732,0,0.999-0.266,1.531-1.397l4.194,2.862
		c-1.198,2.597-3.729,3.596-5.726,3.596c-2.663,0-5.06-1.731-6.125-4.062c-2.263,2.796-5.725,4.062-9.387,4.062
		c-9.52,0-14.646-8.189-14.646-17.31c0-10.119,6.325-17.708,16.511-17.708c1.73,0,4.727,0.266,6.99,0.531v-14.646h5.126V164.023z
		 M375.022,141.123c-1.331-0.2-3.129-0.6-5.592-0.6c-9.454,0-12.649,5.193-12.649,12.316c0,7.057,3.129,12.849,9.387,12.849
		c3.662,0.066,6.524-1.731,8.056-4.86c0.532-1.064,0.799-2.264,0.799-3.728V141.123z"
        />
        <path
          fill="currentColor"
          d="M419.559,164.023c0,0.732,0.732,1.531,1.531,1.531c0.732,0,0.999-0.266,1.531-1.397l4.194,2.862
		c-1.198,2.597-3.729,3.596-5.726,3.596c-2.663,0-5.06-1.731-6.125-4.062c-2.263,2.796-5.725,4.062-9.387,4.062
		c-9.52,0-14.646-8.189-14.646-17.31c0-10.119,6.325-17.708,16.511-17.708c1.73,0,4.727,0.266,6.99,0.531v-14.646h5.126V164.023z
		 M414.433,141.123c-1.331-0.2-3.129-0.6-5.592-0.6c-9.454,0-12.649,5.193-12.649,12.316c0,7.057,3.129,12.849,9.387,12.849
		c3.662,0.066,6.524-1.731,8.056-4.86c0.532-1.064,0.799-2.264,0.799-3.728V141.123z"
        />
        <path
          fill="currentColor"
          d="M466.426,167.02c-1.198,2.597-3.729,3.596-5.726,3.596c-2.663,0-5.06-1.731-6.125-4.062
		c-2.263,2.796-5.725,4.062-9.453,4.062c-9.653,0-14.58-8.322-14.58-17.31c0-10.187,6.325-17.708,16.511-17.708
		c1.997,0,5.459,0.266,7.855,0.599h4.261v27.827c0,0.933,0.799,1.531,1.465,1.531c0.732,0,1.132-0.332,1.598-1.397L466.426,167.02z
		 M454.043,141.057c-2.13-0.134-5.393-0.533-7.323-0.533c-7.189,0-10.918,5.525-10.918,12.648c0,6.459,3.062,12.517,9.387,12.517
		c3.861,0,6.657-1.997,8.056-4.86c0.532-1.064,0.799-2.264,0.799-3.728V141.057z"
        />
      </g>
    </svg>
  );
}
