import { ActivityType } from "../actvities/activity.types";

export type SubscriptionPlan =
  | "free"
  | "basic"
  | "pro"
  | "enterprise"
  | "admin";

export type UsagePeriod = "hour" | "day" | "week" | "month";
export type UsageLimitRule = {
  title: string;
  action: ActivityType;
  limit: number;
  period: UsagePeriod;
  plan: SubscriptionPlan;
  custom?: Record<string, any>;
};

export const SUBSCRIPTION_LABELS: Record<SubscriptionPlan, string> = {
  basic: "The Associate",
  pro: "The Axe",
  enterprise: "The Premier",
  free: "The Freebie",
  admin: "DoTadda Admin",
};
