import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { Link } from "@remix-run/react";
import {
  IoEllipsisHorizontal as HorizontalMenuIcon,
  IoEllipsisVertical as VerticalMenuIcon,
} from "react-icons/io5";
import { cn } from "~/toolkit/components/utils";
import {
  DropdownMenu as ShadDropdownMenu,
  DropdownMenuContent as ShadDropdownMenuContent,
  DropdownMenuItem as ShadDropdownMenuItem,
  DropdownMenuLabel as ShadDropdownMenuLabel,
  DropdownMenuSeparator as ShadDropdownMenuSeparator,
  DropdownMenuTrigger as ShadDropdownMenuTrigger,
} from "../../shadcn/ui/dropdown-menu";
import {
  ConfirmationButton,
  ConfirmationButtonProps,
} from "../modal/ConfirmationButton";

interface DropdownMenuProps extends DropdownMenuPrimitive.DropdownMenuProps {
  children: React.ReactNode;
  trigger?: React.ReactNode | string;
  classNames?: {
    trigger?: string;
    content?: string;
  };
}

export function DropdownMenu({
  children,
  trigger = "Menu",
  classNames = {},
  ...rest
}: DropdownMenuProps) {
  return (
    <ShadDropdownMenu {...rest}>
      <ShadDropdownMenuTrigger
        className={cn(
          `btn btn-ghost hover:bg-gray-100 relative`,
          classNames?.trigger
        )}
      >
        {trigger}
      </ShadDropdownMenuTrigger>
      <ShadDropdownMenuContent
        className={cn("bg-base-100", classNames.content)}
      >
        {children}
      </ShadDropdownMenuContent>
    </ShadDropdownMenu>
  );
}
DropdownMenu.Label = ShadDropdownMenuLabel;
DropdownMenu.Separator = ShadDropdownMenuSeparator;
DropdownMenu.Item = ShadDropdownMenuItem;
DropdownMenu.VerticalMenuIcon = VerticalMenuIcon;
DropdownMenu.HorizontalMenuIcon = HorizontalMenuIcon;
DropdownMenu.ItemWithConfirmation = DropdownMenuItemWithConfirmation;

const ICON_CLASSES = "grid grid-cols-[24px_auto] items-center";

type DropdownMenuIconLink = React.ComponentPropsWithRef<
  typeof ShadDropdownMenuItem
> & {
  icon: React.ReactNode;
  to: string;
  children: string | React.ReactNode;
};

export function DropdownMenuLinkWithIcon({
  icon,
  to,
  children,
  ...rest
}: DropdownMenuIconLink) {
  const label = typeof children === "string" ? children : "";
  return (
    <DropdownMenu.Item asChild aria-label={label}>
      <Link to={to} className={ICON_CLASSES}>
        {icon}
        {children}
      </Link>
    </DropdownMenu.Item>
  );
}
DropdownMenu.LinkWithIcon = DropdownMenuLinkWithIcon;

interface DropdownMenuWithConfirmationProps extends ConfirmationButtonProps {
  icon?: React.ReactNode;
}

function DropdownMenuItemWithConfirmation({
  className,
  icon,
  children,
  ...rest
}: DropdownMenuWithConfirmationProps) {
  let cssClasses = ["w-full text-left", className];
  if (icon) {
    cssClasses.push(ICON_CLASSES);
  }
  return (
    <DropdownMenu.Item
      asChild
      onSelect={(event) => {
        event.preventDefault();
      }}
    >
      <ConfirmationButton {...rest} className={cssClasses.join(" ")}>
        {icon}
        {children}
      </ConfirmationButton>
    </DropdownMenu.Item>
  );
}

type EllipsesMenuProps = Omit<DropdownMenuProps, "trigger"> & {
  ellipses?: "vertical" | "horizontal";
  size?: number;
};

export function EllipsesMenu({
  children,
  ellipses,
  size = 18,
}: EllipsesMenuProps) {
  return (
    <DropdownMenu
      trigger={
        ellipses === "horizontal" ? (
          <HorizontalMenuIcon size={size} />
        ) : (
          <VerticalMenuIcon size={size} />
        )
      }
      classNames={{
        trigger: "btn-circle",
      }}
    >
      {children}
    </DropdownMenu>
  );
}

EllipsesMenu.Label = ShadDropdownMenuLabel;
EllipsesMenu.Separator = ShadDropdownMenuSeparator;
EllipsesMenu.Item = ShadDropdownMenuItem;
EllipsesMenu.VerticalMenuIcon = VerticalMenuIcon;
EllipsesMenu.HorizontalMenuIcon = HorizontalMenuIcon;
EllipsesMenu.LinkWithIcon = DropdownMenuLinkWithIcon;
EllipsesMenu.ItemWithConfirmation = DropdownMenuItemWithConfirmation;
